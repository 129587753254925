@tailwind base;
@tailwind components;
@tailwind utilities;

:root {

    --primary: '#4BAE8A';
    --secondary: '#EEEEEE';
}

.montserrat {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
}

.barlow {
    font-family: 'Barlow', sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    font-style: italic;
}

nav {
    font-size: 15px;
}

.App h1 {
    line-height: 80px;
}

.App .btns a {
    font-size: 15px;
}


.badge {
    letter-spacing: 5px;
    font-weight: bold;
    font-size: 15px;
    text-transform: uppercase;
}

.introduction {
    padding: 100px 150px;
}

.introduction .block {
    border-bottom: 5px solid #4BAE8A;
    border-left: 2px solid #4BAE8A;
    border-right: 2px solid #4BAE8A;
    border-radius: 10px;
    padding: 20px;
    background: white;
}

.introduction .block h3 {
    font-size: 20px;
}

.introduction .block p {
    font-size: 13px;
}

.introduction .block .new {
    color: white;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-35px);
    padding: 5px 30px;
    border-radius: 10px;
    text-transform: uppercase;
    font-size: 13px;
}

.bloc-atout {
    width: 440px;
    height: 440px;
    background: #4BAE8A;
    border-radius: 20px;
}


.bloc-classic-atout a {
    padding: 10px 80px;
    border: 3px solid #4BAE8A;
    font-weight: 500;
    border-radius: 8px;
    text-transform: uppercase;
    font-size: 15px;
    width: fit-content;
    display: flex;
    place-self: flex-end;
}

.bloc-classic-atout a:hover {
    background: #4BAE8A;
    color: white;
}

.bloc-premium-atout a {
    padding: 10px 80px;
    border: 3px solid #FFD43B;
    font-weight: 500;
    border-radius: 8px;
    text-transform: uppercase;
    font-size: 15px;
    width: fit-content;
    color: #FFD43B;
}

.bloc-premium-atout a:hover {
    background: #FFD43B;
    color: white;
}

.btn-classic {
    padding: 10px 80px;
    border: 3px solid #4BAE8A;
    font-weight: 500;
    border-radius: 8px;
    text-transform: uppercase;
    font-size: 15px;
    width: fit-content;
    display: flex;
    place-self: flex-end;
}

.actuality .grid p {
    font-size: 15px;
}

.actuality form input {
    background: #EEEEEE;
    padding: 10px;
    border-radius: 15px 0 0 15px;
    border: 3px solid #4BAE8A;
    font-size: 15px;
    max-width: 350px;
    width: 100%;
    color: #4BAE8A;
    padding-left: 16px;
}

.actuality form button {
    position: relative;
    right: 30px;
    border-radius: 15px;
    background: #4BAE8A;
    padding: 0 50px;
    height: 48.5px;
    align-items: center;
}

footer li {
    font-size: 13px;
    padding: 5px 0;
}

footer ul h3 {
    font-size: 20px;
}

@media screen and (max-width: 768px) {

    .App h1 {
        line-height: 50px;
    }

    .introduction {
        padding: 100px 1.25rem;
    }

    .bloc-atout {
        margin: auto;
        width: 250px;
        height: 250px;
    }

    .bloc-classic-atout a {
        margin: auto;
        margin-top: 20px;
        padding: 10px 50px;
    }

    .bloc-premium-atout a {
        margin: auto;
        margin-top: 20px;
        padding: 10px 50px;
    }

    .actuality .grid p {
        font-size: 13px;
    }
}

input {
    background: #EEEEEE;
    padding: 10px;
    border-radius: 8px;
    border: 3px solid #4BAE8A;
    font-size: 15px;
    max-width: 350px;
    width: 100%;
    color: #4BAE8A;
    padding-left: 16px;
}

button {
    padding: 10px 80px;
    border: 3px solid #4BAE8A;
    font-weight: 500;
    border-radius: 8px;
    text-transform: uppercase;
    font-size: 15px;
    width: fit-content;
    display: flex;
    place-self: flex-end;
}

.auth {
    border: 3px solid #4BAE8A;
    border-radius: 20px;
    padding: 30px 40px;
    width: 600px;
}



.container-left {
    border-right: 1px solid #EEEEEE;
    padding: 20px;
}

.container-right {
    border-left: 1px solid #EEEEEE;
}

.container-discover {
    border-top: 1px solid #EEEEEE;
}

.container-center {
    margin: 20px 100px;
}

.barlow-discover {
    font-family: 'Barlow', sans-serif;
    text-transform: uppercase;
    font-weight: 200;
    font-style: italic;
    font-size: 20px;
}

.barlow-discover .number {
    color: #A1A1A1;
    font-size: 12px;
}

.container-g-icon {
    width: max-content;
}

.container-icon {
    border: 1px solid #EEEEEE;
    padding: 40px;
    border-radius: 10px;
    height: 128px;
    width: 128px;
}

.container-icon p {
    position: relative;
    text-align: center;
    font-size: 13px;
    margin-bottom: 0;
    margin-top: 10px;
    color: #A1A1A1;
}

.container-left .button-filter {
    border-radius: 5px;
    padding: 3px 10px;
    color: #000;
    font-size: 13px;
    border-color: #fff;
}

.container-left .button-filter:hover {
    border-radius: 5px;
    padding: 3px 10px;
    color: #000;
    border-color: #EEEEEE;
    background-color: #EEEEEE;
}

.container-left .button-filter:focus {
    background: #4BAE8A;
    color: white;
    border-color: #4BAE8A;
}

.container-center .searchbar {
    background: white;
    border: 2px solid #EEEEEE;
    border-radius: 8px;
    color: #A1A1A1;
    font-size: 12px;
    height: 30px;
    padding-right: 20px;
}

.container-center .search-bar .button-searchbar {
    border-radius: 8px;
    padding: 8px;
    background: #4BAE8A;
    margin-left: -13px;
}


.container-left .button-categories {
    border: 1px solid #EEEEEE;
    background-color: white;
    /* Ensure background is white when not selected */
    padding: 7px 17px;
    stroke: #000;
}

.container-left .button-categories:hover {
    border-color: #EEEEEE;
    background-color: #EEEEEE;
}

.container-left .button-categories:focus {
    background: #4BAE8A;
    border-color: #4BAE8A;
    stroke: #fff;
}


.container-left .button-categories.selected {
    background: #4BAE8A;
    border-color: #4BAE8A;
    stroke: white;
}

.container-left .button-filter.selected {
    background: #4BAE8A;
    color: white;
    border-color: #4BAE8A;
}

.palette-discover span {
    position: absolute;
    margin-top: -35px;
    right: 40px;
}

.badge-icon {
    position: absolute;
    top: 0;
    right: 0;
    background: #4BAE8A;
    border-radius: 0 8px 0 8px;
    font-size: 10px;
    padding: 2px 5px;
    color: white;
}

.button-customize-start {
    border-top: 1px solid #EEEEEE;
    border-left: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE;
    border-right: none;
    border-radius: 8px 0 0 8px;
    background-color: white;
    /* Ensure background is white when not selected */
    padding: 7px 17px;
    stroke: #000;
}

.button-customize {
    border-top: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE;
    border-left: none;
    border-right: none;
    border-radius: 0;
    background-color: white;
    /* Ensure background is white when not selected */
    padding: 7px 17px;
    stroke: #000;
}

.button-customize-end {
    border-top: 1px solid #EEEEEE;
    border-right: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE;
    border-left: none;
    border-radius: 0px 8px 8px 0;
    background-color: white;
    /* Ensure background is white when not selected */
    padding: 7px 17px;
    stroke: #000;
}

.btn-custom:hover {
    border-color: #EEEEEE;
    background-color: #EEEEEE;
}

.btn-custom:focus {
    background: #4BAE8A;
    border-color: #4BAE8A;
    color: #fff;
}

.btn-custom.selected {
    background: #4BAE8A;
    color: white;
    border-color: #4BAE8A;
}

.container-right .module-customise {
    padding: 20px;
}

.container-right .module-code {
    border-top: 1px solid #EEEEEE;
    padding: 20px;
}

.container-right .module-about {
    border-top: 1px solid #EEEEEE;
    padding: 20px;
}

.lightbox-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    /* Fond sombre et transparent */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Assurez-vous que la lightbox est au-dessus des autres éléments */
}

.lightbox-content {
    background: white;
    /* Fond blanc pour le contenu de la lightbox */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
    width: 1000px;
    /* Largeur maximale */
    height: 500px;
    /* Hauteur maximale */
    overflow: auto;
    /* Permet le défilement si le contenu dépasse */
    display: flex;
}

.lightbox-content .box-icon {
    width: 400px;
    height:max-content;
    border-radius: 8px;
    border: 3px solid #eee;
    padding: 10px;
    background-color: white;
}

.lightbox-icon svg {
    width: 256px;
    margin: auto;
}

.close-button {
    border: none;
}

.lightbox-content-premium {
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
    width: 1000px;
    /* Largeur maximale */
    height: 500px;
    /* Hauteur maximale */
    overflow: auto;
    /* Permet le défilement si le contenu dépasse */
    display: flex;
}

.lightbox-content-premium .premium a {
    width: max-content;
}


.btn-primary {
    padding: 10px 80px;
    border: 3px solid #4BAE8A;
    font-weight: 500;
    border-radius: 8px;
    text-transform: uppercase;
    font-size: 15px;
    width: fit-content;
    
}


nav .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.download-lightbox {
    padding: 10px 40px;
    border: 3px solid #4BAE8A;
    font-weight: 500;
    border-radius: 8px;
    text-transform: uppercase;
    font-size: 15px;
    width: fit-content;
    display: flex;
    place-self: flex-end;
}

